import { createReport, getReports, getReportById, updateReport, deleteReport, getReportsByUser, searchReports } from '../api/reports';

const state = {
  reports: [],
  selectedReport: null,
};

const mutations = {
  setReports(state, reports) {
    state.reports = reports;
  },
  setSelectedReport(state, report) {
    state.selectedReport = report;
  },
  addReport(state, report) {
    state.reports.push(report);
  },
  updateReport(state, updatedReport) {
    const index = state.reports.findIndex(report => report.id === updatedReport.id);
    if (index !== -1) {
      state.reports.splice(index, 1, updatedReport);
    }
  },
  deleteReport(state, reportId) {
    state.reports = state.reports.filter(report => report.id !== reportId);
  },
};

const actions = {
  async fetchReports({ commit }) {
    try {
      const reports = await getReports();
      commit('setReports', reports);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  },
  async createReport({ commit }, reportData) {
    try {
      const newReport = await createReport(reportData);
      commit('addReport', newReport);
    } catch (error) {
      console.error('Error creating report:', error);
    }
  },
  async fetchReportById({ commit }, id) {
    try {
      const report = await getReportById(id);
      commit('setSelectedReport', report);
    } catch (error) {
      console.error('Error fetching report by id:', error);
    }
  },
  async editReport({ commit }, { id, reportData }) {
    try {
      const updatedReport = await updateReport(id, reportData);
      commit('updateReport', updatedReport);
    } catch (error) {
      console.error('Error updating report:', error);
    }
  },
  async removeReport({ commit }, reportId) {
    try {
      await deleteReport(reportId);
      commit('deleteReport', reportId);
    } catch (error) {
      console.error('Error deleting report:', error);
    }
  },
  async fetchReportsByUser(_, userId) {
    try {
      const reports = await getReportsByUser(userId);
      return reports;
    } catch (error) {
      console.error('Error fetching reports by user:', error);
      throw error;
    }
  },
  async searchReports(_, { type, query }) {
    try {
      const reports = await searchReports(type, query);
      return reports;
    } catch (error) {
      console.error('Error searching reports:', error);
      throw error;
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
