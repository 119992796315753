<template>
  <div class="container">
    <h1 class="my-4">Dashboard</h1>
    <div class="row mb-3">
      <div class="col-12 col-md-6 col-lg-4 mb-2">
        <button @click="toggleProfile" class="btn btn-primary btn-block">
          <i class="fas fa-user"></i> Ver Perfil
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-2" v-if="isAdmin">
        <button @click="toggleUserManagement" class="btn btn-secondary btn-block">
          <i class="fas fa-users-cog"></i> Administrar Usuarios
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-2">
        <button @click="toggleSearchReports" class="btn btn-primary btn-block">
          <i class="fas fa-search"></i> Buscar Reportes
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-2" v-if="isAdmin">
        <button @click="showAllReports" class="btn btn-secondary btn-block">
          <i class="fas fa-file-alt"></i> Todos los Reportes
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-2">
        <button @click="showMyReports" class="btn btn-primary btn-block">
          <i class="fas fa-file-alt"></i> Mis Reportes
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-2">
        <button @click="addReport" class="btn btn-secondary btn-block">
          <i class="fas fa-plus"></i> Agregar Reporte
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-2" v-if="isOwnerOrAdmin">
        <button @click="showMyReservations" class="btn btn-primary btn-block">
          <i class="fas fa-calendar-alt"></i> Mis Reservas
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-2" v-if="isAdmin">
        <button @click="showAllReservations" class="btn btn-secondary btn-block">
          <i class="fas fa-calendar-alt"></i> Todas las Reservas
        </button>
      </div>
    </div>

    <div v-if="showSearchForm" class="mb-4">
      <h3>Buscar Reportes</h3>
      <div class="form-group">
        <label for="searchType">Buscar por:</label>
        <div>
          <label class="mr-2">
            <input type="radio" v-model="searchType" value="rut" /> RUT
          </label>
          <label class="mr-2">
            <input type="radio" v-model="searchType" value="dni" /> DNI
          </label>
          <label class="mr-2">
            <input type="radio" v-model="searchType" value="passport" /> Pasaporte
          </label>
          <label class="mr-2">
            <input type="radio" v-model="searchType" value="phone" /> Teléfono
          </label>
        </div>
      </div>
      <div class="form-group">
        <input type="text" v-model="searchQuery" class="form-control" placeholder="Ingrese el valor a buscar" />
      </div>
      <button @click="searchReports" class="btn btn-primary">Buscar</button>
    </div>

    <ProfileCard v-if="showProfile" />
    <UserManagement v-if="showUserManagement" />
    <ReportForm v-if="showReportForm" @reportCreated="handleReportCreated" />
    <AllReports v-if="showAllReportsComponent" />
    <MyReports v-if="showMyReportsComponent" :reports="myReports" />
    <SearchComponent v-if="showSearchComponent" />
   
    <div v-if="showSuccessMessage" class="alert alert-success mt-3">
      <p>Reporte creado exitosamente</p>
      <button @click="closeSuccessMessage" class="btn btn-secondary">Cerrar</button>
      <button @click="createAnotherReport" class="btn btn-primary">Crear otro reporte</button>
    </div>
    <div v-if="showComingSoonMessage" class="alert alert-info mt-3">
      <p>Próximamente</p>
      <button @click="closeComingSoonMessage" class="btn btn-secondary">Cerrar</button>
    </div>
    <button v-if="showProfile || showUserManagement || showReportForm || showAllReportsComponent || showMyReportsComponent" @click="refreshScreen" class="btn btn-secondary mt-3">
      <i class="fas fa-sync-alt"></i> Refrescar Pantalla
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProfileCard from '../components/ProfileCard.vue';
import UserManagement from '../components/UserManagement.vue';
import ReportForm from '../components/ReportForm.vue';
import AllReports from '../components/AllReports.vue';
import MyReports from '../components/MyReports.vue';
import SearchComponent from '../components/SearchComponent.vue';

export default {
  name: 'DashboardView',
  components: {
    ProfileCard,
    UserManagement,
    ReportForm,
    AllReports,
    MyReports,
    SearchComponent,
  },
  data() {
    return {
      showProfile: false,
      showUserManagement: false,
      showReportForm: false,
      showAllReportsComponent: false,
      showMyReportsComponent: false,
      showSuccessMessage: false,
      showSearchForm: false,
      showComingSoonMessage: false,
      myReports: [],
      searchType: 'rut',
      searchQuery: '',
      showSearchComponent: false,
    };
  },
  computed: {
    ...mapState(['user']),
    isAdmin() {
      return this.user && this.user.role === 'admin';
    },
    isOwnerOrAdmin() {
      return this.user && (this.user.role === 'owner' || this.user.role === 'admin');
    },
  },
  methods: {
    toggleProfile() {
      this.refreshScreen();
      this.showProfile = true;
    },
    toggleUserManagement() {
      this.refreshScreen();
      this.showUserManagement = true;
    },
    toggleSearchReports() {
      this.refreshScreen();
      this.showSearchComponent = true;
    },
    addReport() {
      this.refreshScreen();
      this.showReportForm = true;
    },
    showAllReports() {
      this.refreshScreen();
      this.showAllReportsComponent = true;
    },
    async showMyReports() {
      this.refreshScreen();
      try {
        const response = await this.$store.dispatch('fetchReportsByUser', this.user.uid);
        console.log('Reports fetched:', response); // Agregar mensaje de consola
        this.myReports = response;
        this.showMyReportsComponent = true;
      } catch (error) {
        console.error('Error fetching user reports:', error);
      }
    },
    async searchReports() {
      try {
        const response = await this.$store.dispatch('searchReports', { type: this.searchType, query: this.searchQuery });
        console.log('Search results:', response); // Agregar mensaje de consola
        this.myReports = response;
        this.showMyReportsComponent = true;
      } catch (error) {
        console.error('Error searching reports:', error);
      }
    },
    showMyReservations() {
      this.showComingSoonMessage = true;
    },
    showAllReservations() {
      this.showComingSoonMessage = true;
    },
    closeComingSoonMessage() {
      this.showComingSoonMessage = false;
    },
    handleReportCreated() {
      this.refreshScreen();
      this.showSuccessMessage = true;
    },
    closeSuccessMessage() {
      this.showSuccessMessage = false;
    },
    createAnotherReport() {
      this.refreshScreen();
      this.showReportForm = true;
    },
    refreshScreen() {
      this.showProfile = false;
      this.showUserManagement = false;
      this.showReportForm = false;
      this.showAllReportsComponent = false;
      this.showMyReportsComponent = false;
      this.showSuccessMessage = false;
      this.showSearchForm = false;
      this.showComingSoonMessage = false;
      this.showSearchComponent = false;
    },
  },
};
</script>

<style scoped>
/* Estilos movidos a alerts.css */
</style>
