<template>
  <div class="container">
    <h1 class="my-4">Panel de Administración</h1>
    <div v-if="loading" class="text-center my-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Cargando...</span>
      </div>
      <p>Cargando usuarios...</p>
    </div>
    <div v-else>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.uid">
            <td>{{ user.nombre }} {{ user.apellido }}</td>
            <td>{{ user.email }}</td>
            <td>
              <button @click="activateUser(user.uid)" class="btn btn-primary btn-sm">
                <i class="fas fa-check"></i> Activar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: true,
      error: null
    };
  },
  computed: {
    ...mapState('admin', ['users'])
  },
  methods: {
    ...mapActions('admin', ['fetchUsers', 'activateUser']),
    async loadUsers() {
      this.loading = true;
      try {
        await this.fetchUsers();
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.loadUsers();
  }
};
</script>

<style scoped>
.spinner-border {
  width: 3rem;
  height: 3rem;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
</style>
