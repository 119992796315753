<template>
  <div class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Recuperar Contraseña</h5>
          <button type="button" class="close" @click="closeModal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="email">Correo Electrónico</label>
              <input type="email" v-model="email" required class="form-control" />
            </div>
            <button type="submit" class="btn btn-primary">
              <i class="fas fa-paper-plane"></i> Enviar
            </button>
          </form>
          <p v-if="message">{{ message }}</p>
          <p v-if="error">{{ error }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendPasswordReset } from "@/api/auth";

export default {
  data() {
    return {
      email: "",
      message: "",
      error: ""
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await sendPasswordReset(this.email);
        this.message = response.message;
        this.error = "";
      } catch (error) {
        this.error = error.message;
        this.message = "";
      }
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
/* Estilos movidos a modals.css */
</style>
