<template>
  <div class="container">
    <h1 class="my-4">Detalle del Reporte</h1>
    <div v-if="report" class="row report-details">
      <div class="col-md-6">
        <p><strong>ID:</strong> {{ report.uid }}</p>
        <p><strong>Nombre:</strong> {{ report.nombre }}</p>
        <p><strong>Fecha de Creación:</strong> {{ report.createdAt }}</p>
        <!-- Agrega más campos según sea necesario -->
      </div>
      <div class="col-md-6">
        <p><strong>Apellido:</strong> {{ report.apellido }}</p>
        <p><strong>Género:</strong> {{ report.genero }}</p>
        <p><strong>Email:</strong> {{ report.email }}</p>
        <!-- Agrega más campos según sea necesario -->
      </div>
      <div class="col-12 mt-3" v-if="canEditOrDelete">
        <button @click="editReport" class="btn btn-secondary">
          <i class="fas fa-edit"></i> Editar
        </button>
        <button @click="deleteReport" class="btn btn-danger">
          <i class="fas fa-trash-alt"></i> Eliminar
        </button>
        <button @click="showReportModal" class="btn btn-primary">
          <i class="fas fa-eye"></i> Ver Detalles
        </button>
      </div>
    </div>
    <div v-else>
      <p>Cargando...</p>
    </div>
    <ReportModal :report="report" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ReportModal from '@/components/ReportModal.vue';
import bootstrap from 'bootstrap'; // Add this line

export default {
  components: {
    ReportModal
  },
  data() {
    return {
      reportId: this.$route.params.id,
    };
  },
  computed: {
    ...mapState({
      report: state => state.reports.selectedReport,
    }),
    canEditOrDelete() {
      return this.$store.state.user.role === 'admin' || this.report.createdBy === this.$store.state.user.uid;
    },
  },
  methods: {
    ...mapActions(['fetchReportById', 'removeReport']),
    async fetchReport() {
      await this.fetchReportById(this.reportId);
    },
    editReport() {
      this.$router.push({ name: 'EditReport', params: { id: this.reportId } });
    },
    async deleteReport() {
      await this.removeReport(this.reportId);
      this.$router.push({ name: 'Reports' });
    },
    showReportModal() {
      const modal = new bootstrap.Modal(document.getElementById('reportModal'));
      modal.show();
    }
  },
  created() {
    this.fetchReport();
  },
};
</script>

<style scoped>
/* Estilos movidos a report-detail.css */
</style>
