<template>
  <div class="home container">
    <AboutView />
  </div>
</template>

<script>
import AboutView from "@/views/AboutView.vue";

export default {
  name: "HomeView",
  components: {
    AboutView,
  },
};
</script>

<style scoped>
/* Estilos movidos a home.css */
</style>
