import { createStore } from 'vuex';
import { login, register, logout } from '@/api/auth';
import { getAllUsers, updateUserData, deleteUser } from '@/api/user';
import reports from './reports';
import router from '../router';
import axios from 'axios';

export default createStore({
  state: {
    user: null,
    users: [],
    error: null
  },
  getters: {
    isAuthenticated: state => !!state.user,
    getError: state => state.error,
    isAdmin: state => state.user && state.user.role === 'admin' // Define the isAdmin getter
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setError(state, error) {
      state.error = error;
    },
    clearUser(state) {
      state.user = null;
    }
  },
  actions: {
    async loginUser({ commit }, { email, password }) {
      try {
        const response = await login({ email, password });
        const token = response.token;
        // Save the token in local storage or cookies
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        commit('setUser', response.user); // Ensure the user object is set correctly
        commit('setError', null);
      } catch (error) {
        commit('setError', error.message);
      }
    },
    async registerUser({ commit }, userData) {
      try {
        await register(userData);
        commit('setError', null);
      } catch (error) {
        commit('setError', error.message);
      }
    },
    logoutUser({ commit }) {
      logout();
      commit('clearUser');
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      router.push('/login');
    },
    async loadUsers({ commit }) {
      try {
        const users = await getAllUsers();
        commit('setUsers', users);
      } catch (error) {
        commit('setError', error.message);
      }
    },
    async updateUserRole({ commit, state }, user) {
      try {
        await updateUserData(user);
        const updatedUsers = state.users.map(u => (u.id === user.id ? user : u));
        commit('setUsers', updatedUsers);
      } catch (error) {
        commit('setError', error.message);
      }
    },
    async updateUserStatus({ commit, state }, user) {
      try {
        await updateUserData(user);
        const updatedUsers = state.users.map(u => (u.id === user.id ? user : u));
        commit('setUsers', updatedUsers);
      } catch (error) {
        commit('setError', error.message);
      }
    },
    async deleteUser({ commit, state }, userId) {
      try {
        await deleteUser(userId);
        const updatedUsers = state.users.filter(u => u.id !== userId);
        commit('setUsers', updatedUsers);
      } catch (error) {
        commit('setError', error.message);
      }
    },
    async tryAutoLogin({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post('/api/refresh-token', {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const newToken = response.data.token;
          localStorage.setItem('token', newToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          commit('setUser', response.data.user);
        } catch (error) {
          console.error('Auto-login failed:', error);
          commit('clearUser');
          localStorage.removeItem('token');
        }
      }
    }
  },
  modules: {
    reports,
  }
});
