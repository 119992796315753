<template>
  <div class="container" v-if="isAdmin">
    <h2>Gestión de Usuarios</h2>
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Rol</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in paginatedUsers" :key="user.id">
            <td>{{ user.nombre }} {{ user.apellido }}</td>
            <td>{{ user.email }}</td>
            <td>
              <select v-model="user.role" @change="updateUserRole(user)" class="form-control" :disabled="user.role === 'admin'">
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="owner">Owner</option>
                <option value="ownerpro">Owner Pro</option>
                <option value="supervisor">Supervisor</option>
                <option value="master">Master</option>
              </select>
            </td>
            <td>
              <select v-model="user.enabled" @change="updateUserStatus(user)" class="form-control">
                <option :value="true">Enabled</option>
                <option :value="false">Disabled</option>
              </select>
            </td>
            <td>
              <button @click="editUser" class="btn btn-secondary">
                <i class="fas fa-edit"></i> Editar
              </button>
              <button @click="deleteUser(user.id)" class="btn btn-danger" :disabled="user.role === 'admin'">
                <i class="fas fa-trash-alt"></i> Borrar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav>
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Anterior</a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Siguiente</a>
        </li>
      </ul>
    </nav>
  </div>
  <div v-else>
    <p>No tienes permiso para acceder a esta sección.</p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'UserManagement',
  data() {
    return {
      currentPage: 1,
      usersPerPage: 25,
    };
  },
  computed: {
    ...mapState(['users', 'user']),
    sortedUsers() {
      return this.users.slice().sort((a, b) => a.createdAt - b.createdAt);
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.usersPerPage;
      const end = start + this.usersPerPage;
      return this.sortedUsers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.sortedUsers.length / this.usersPerPage);
    },
    isAdmin() {
      return this.user && this.user.role === 'admin';
    }
  },
  methods: {
    ...mapActions(['loadUsers', 'editUser', 'deleteUser', 'updateUserRole', 'updateUserStatus']),
    editUser() {
      // Logic to edit user
    },
    deleteUser(userId) {
      this.deleteUser(userId);
    },
    updateUserRole(user) {
      this.updateUserRole(user);
    },
    updateUserStatus(user) {
      this.updateUserStatus(user);
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
  mounted() {
    this.loadUsers();
  },
};
</script>

<style scoped>
/* Estilos movidos a tables.css y pagination.css */
</style>
