import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import Login from "@/views/LoginView.vue";
import Register from "@/views/RegisterView.vue";
import Dashboard from "@/views/DashboardView.vue";
import AdminPanelView from "@/views/AdminPanelView.vue";
import ProfileView from "@/views/ProfileView.vue";
import Reports from "@/views/Reports.vue";
import ReportDetail from "@/views/ReportDetail.vue";
import NewReport from "@/views/NewReport.vue";
import store from '../store';

const routes = [
  { path: "/", component: Home },
  { path: "/login", name: "Login", component: Login },
  { path: "/register", component: Register },
  { path: "/dashboard", component: Dashboard, meta: { requiresAuth: true } },
  {
    path: '/admin',
    name: 'AdminPanel',
    component: AdminPanelView,
    beforeEnter: (to, from, next) => {
      const user = store.state.user;
      if (user && user.role === 'admin') {
        next();
      } else {
        next('/'); // Redirect to home or login page
      }
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: { requiresAuth: true },
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: () => import('../components/UserManagement.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.user.role === 'admin') {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/reports/:id',
    name: 'ReportDetail',
    component: ReportDetail,
    props: true
  },
  {
    path: '/reports/new',
    name: 'NewReport',
    component: NewReport
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  console.log("¿Token encontrado en localStorage?", localStorage.getItem('token') ? "Sí" : "No");
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      store.dispatch('tryAutoLogin').then(() => {
        if (!store.getters.isAuthenticated) {
          next({ name: 'Login' });
        } else {
          next();
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
