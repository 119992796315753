<template>
  <div class="modal fade" id="reportModal" tabindex="-1" aria-labelledby="reportModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reportModalLabel">Detalle del Reporte</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="report" class="report-details">
            
            <p><strong>Nombre:</strong> {{ report.nombre }}</p>
            <p><strong>Fecha de Creación:</strong> {{ report.createdAt }}</p>
            <p><strong>Apellido:</strong> {{ report.apellido }}</p>
            <p><strong>Género:</strong> {{ report.genero }}</p>
            <p><strong>Email:</strong> {{ report.email }}</p>
            <!-- Agrega más campos según sea necesario -->
          </div>
          <div v-else>
            <p>Cargando...</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
/* Estilos movidos a modals.css */
</style>
