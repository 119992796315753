<template>
  <div class="container">
    <h1 class="my-4">Perfil</h1>
    <form @submit.prevent="updateProfile" class="form">
      <div class="form-group">
        <label for="firstName">Nombre:</label>
        <input type="text" v-model="user.firstName" id="firstName" class="form-control" />
        <span v-if="errors.firstName" class="text-danger">{{ errors.firstName }}</span>
      </div>
      <div class="form-group">
        <label for="lastName">Apellido:</label>
        <input type="text" v-model="user.lastName" id="lastName" class="form-control" />
        <span v-if="errors.lastName" class="text-danger">{{ errors.lastName }}</span>
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="user.email" id="email" class="form-control" />
        <span v-if="errors.email" class="text-danger">{{ errors.email }}</span>
      </div>
      <!-- Add more fields as needed -->
      <button type="submit" class="btn btn-primary">Guardar</button>
    </form>
    <p v-if="confirmationMessage" :class="{'text-success': isSuccess, 'text-danger': !isSuccess}">
      {{ confirmationMessage }}
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { onMounted } from 'vue';
import { updateUserDataFirebase } from '../api/user';

export default {
  name: 'ProfileView',
  data() {
    return {
      errors: {},
      confirmationMessage: '',
      isSuccess: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions(['loadUser', 'updateUser']),
    validateForm() {
      this.errors = {};
      if (!this.user.firstName) {
        this.errors.firstName = 'Nombre es requerido';
      }
      if (!this.user.lastName) {
        this.errors.lastName = 'Apellido es requerido';
      }
      if (!this.user.email) {
        this.errors.email = 'Email es requerido';
      } else if (!this.validEmail(this.user.email)) {
        this.errors.email = 'Email inválido';
      }
      return Object.keys(this.errors).length === 0;
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(email);
    },
    async updateProfile() {
      if (this.validateForm()) {
        try {
          await this.updateUser(this.user);
          await updateUserDataFirebase(this.user);
          this.confirmationMessage = 'Perfil actualizado exitosamente';
          this.isSuccess = true;
        } catch (error) {
          console.error('Error actualizando perfil:', error);
          this.confirmationMessage = 'Error actualizando perfil';
          this.isSuccess = false;
        }
      }
    },
  },
  setup() {
    const { loadUser } = mapActions(['loadUser']);
    onMounted(() => {
      loadUser();
    });
  },
};
</script>

<style scoped>
/* Estilos movidos a profile.css */
</style>
