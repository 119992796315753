<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title">Perfil del Usuario</h2>
      <p class="card-text"><strong>Nombre:</strong> {{ user.firstName }} {{ user.lastName }}</p>
      <p class="card-text"><strong>Email:</strong> {{ user.email }}</p>
      <!-- Add more fields as needed -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProfileCard',
  computed: {
    ...mapState(['user']),
  },
};
</script>

<style scoped>
/* Estilos movidos a cards.css */
</style>
