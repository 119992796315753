<template>
  <div id="app">
    <NavBarComponent />
    <router-view />
    <ScrollToTopButton />
  </div>
</template>

<script>
import NavBarComponent from './components/NavBarComponent.vue';
import ScrollToTopButton from './components/ScrollToTopButton.vue';

export default {
  components: {
    NavBarComponent,
    ScrollToTopButton
  }
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@/assets/styles/global.css";
@import "@/assets/styles/styles.css";
@import "@/assets/styles/buttons.css";
@import "@/assets/styles/navbar.css";
@import "@/assets/styles/tables.css";
@import "@/assets/styles/modals.css";
@import "@/assets/styles/cards.css";
@import "@/assets/styles/pagination.css";
@import "@/assets/styles/about.css";
@import "@/assets/styles/alerts.css";
@import "@/assets/styles/home.css";
@import "@/assets/styles/login.css";
@import "@/assets/styles/profile.css";
@import "@/assets/styles/register.css";
@import "@/assets/styles/report-detail.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: var(--text-color);
}

nav a.router-link-exact-active {
  color: var(--primary-color);
}
</style>
