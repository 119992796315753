import axios from 'axios';

const API_PROD_URL = 'https://us-central1-mundo-informativo.cloudfunctions.net/api';

export const getUserData = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("❌ No hay token en localStorage para obtener datos del usuario.");
      return null;
    }
    const response = await axios.get(`${API_PROD_URL}/me`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("✅ Datos del usuario obtenidos correctamente:", response.data);
    return response.data;
  } catch (error) {
    console.error('🚨 Error al obtener datos del usuario:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const updateUserData = async (userData) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("❌ No hay token en localStorage para actualizar datos del usuario.");
      return null;
    }
    const response = await axios.put(`${API_PROD_URL}/me`, userData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("🔄 Datos del usuario actualizados correctamente:", response.data);
    return response.data;
  } catch (error) {
    console.error('🚨 Error al actualizar datos del usuario:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const updateUserDataFirebase = async (userData) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("❌ No hay token en localStorage para actualizar en Firebase.");
      return null;
    }
    const response = await axios.post(`${API_PROD_URL}/updateFirebase`, userData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("🔥 Datos del usuario actualizados en Firebase:", response.data);
    return response.data;
  } catch (error) {
    console.error('🚨 Error al actualizar datos del usuario en Firebase:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("❌ No hay token en localStorage para obtener todos los usuarios.");
      return null;
    }
    const response = await axios.get(`${API_PROD_URL}/users`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("📋 Lista de usuarios obtenida correctamente:", response.data);
    return response.data;
  } catch (error) {
    console.error('🚨 Error al obtener la lista de usuarios:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const getUserById = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("❌ No hay token en localStorage para obtener datos del usuario por ID.");
      return null;
    }
    const response = await axios.get(`${API_PROD_URL}/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("🔍 Datos del usuario obtenidos por ID:", response.data);
    return response.data;
  } catch (error) {
    console.error('🚨 Error al obtener datos del usuario por ID:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const updateUserRole = async (userId, role) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("❌ No hay token en localStorage para actualizar el rol del usuario.");
      return null;
    }
    const response = await axios.put(`${API_PROD_URL}/users/${userId}`, { role }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("🔄 Rol del usuario actualizado correctamente:", response.data);
    return response.data;
  } catch (error) {
    console.error('🚨 Error al actualizar el rol del usuario:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const updateUserStatus = async (userId, enabled) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("❌ No hay token en localStorage para actualizar el estado del usuario.");
      return null;
    }
    const response = await axios.put(`${API_PROD_URL}/users/${userId}`, { enabled }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("🔄 Estado del usuario actualizado correctamente:", response.data);
    return response.data;
  } catch (error) {
    console.error('🚨 Error al actualizar el estado del usuario:', error.response?.data?.message || error.message);
    throw error;
  }
};
