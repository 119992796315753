<template>
  <div class="container">
    <h1>Reportes</h1>
    <table class="table table-responsive">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th>Fecha de Creación</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>{{ report.id }}</td>
          <td>{{ report.nombre }}</td>
          <td>{{ report.createdAt }}</td>
          <td>
            <button @click="viewDetails(report.id)" class="btn btn-primary">
              <i class="fas fa-eye"></i> Ver Detalles
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      reports: state => state.reports.reports
    })
  },
  methods: {
    viewDetails(id) {
      this.$router.push({ name: 'ReportDetail', params: { id } });
    }
  },
  created() {
    this.$store.dispatch('fetchReports');
  }
};
</script>

<style scoped>
/* Estilos movidos a tables.css */
</style>
