<template>
  <div class="call-to-action text-center py-5">
    <h2>Únete a nuestra comunidad de Dueños de Hospedajes</h2>
    <p>Regístrate para acceder a todas nuestras funcionalidades y beneficios.</p>
    <button @click="goToRegister" class="btn btn-primary btn-lg mx-2">
      <i class="fas fa-user-plus"></i> Registrarse
    </button>
  </div>
</template>

<script>
export default {
  name: 'CallToAction',
  methods: {
    goToRegister() {
      this.$router.push('/register');
    }
  }
};
</script>

<style scoped>
@import "@/assets/styles/call-to-action.css";
</style>
