<template>
    <div class="container">
      <h2 class="my-4">Todos los Reportes</h2>
      <input type="text" v-model="searchQuery" placeholder="Buscar reportes..." @input="filterReports" class="form-control mb-3" />
      <div class="form-group">
        <label for="searchType">Buscar por:</label>
        <div>
          <label class="mr-2">
            <input type="radio" v-model="searchType" value="nombre" /> Nombre
          </label>
          <label class="mr-2">
            <input type="radio" v-model="searchType" value="apellido" /> Apellido
          </label>
          <label class="mr-2">
            <input type="radio" v-model="searchType" value="identificacion" /> Identificación
          </label>
        </div>
      </div>
     
      
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Género</th>
              <th>Nicknames</th>
              <th>Teléfono</th>
              <th>Tipo id</th>
              <th>Identificación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="report in paginatedReports" :key="report.id">
              <td>{{ report.nombre }}</td>
              <td>{{ report.apellido }}</td>
              <td>{{ report.genero }}</td>
              <td>{{ report.nickNames.join(', ') }}</td>
              <td>{{ report.telefono.map(t => `${t.countryCode} ${t.number}`).join(', ') }}</td>
              <td>{{ report.idType }}</td>
              <td>{{ report.identificacion }}</td>
              <td>
                <button @click="viewReport(report)" class="btn btn-primary btn-sm">
                  <i class="fas fa-eye"></i> Ver
                </button>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav>
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Anterior</a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Siguiente</a>
          </li>
        </ul>
      </nav>
      <div v-if="showModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Detalles del Reporte</h5>
              <button type="button" class="close" @click="closeModal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              
              <p><strong>Nombre:</strong> {{ selectedReport.nombre }}</p>
              <p><strong>Apellido:</strong> {{ selectedReport.apellido }}</p>
              <p><strong>Nicknames:</strong> {{ selectedReport.nickNames.join(', ') }}</p>
              <p><strong>Nacionalidad:</strong> {{ selectedReport.nacionalidad }}</p>
              <p><strong>Tipo de Identificación:</strong> {{ selectedReport.idType }}</p>
              <p><strong>Identificación:</strong> {{ selectedReport.identificacion }}</p>
              <p><strong>Género:</strong> {{ selectedReport.genero }}</p>
              <p><strong>Teléfono:</strong> {{ selectedReport.telefono.map(t => `${t.countryCode} ${t.number}`).join(', ') }}</p>
              <p><strong>Email:</strong> {{ selectedReport.email }}</p>
              <p><strong>Paga Puntual:</strong> {{ selectedReport.paga_puntual }}</p>
              <p><strong>Habitación Limpia y Ordenada:</strong> {{ selectedReport.habitacionLimpiaYOrdenada }}</p>
              <p><strong>Tranquila y Ordenada:</strong> {{ selectedReport.tranquilaYOrdenada }}</p>
              <p><strong>Consume Marihuana:</strong> {{ selectedReport.consumeMarihuana }}</p>
              <p><strong>Consume Otras Drogas:</strong> {{ selectedReport.consumeOtrasDrogas }}</p>
              <p><strong>Consumo Alcohol Excesivo:</strong> {{ selectedReport.consumoAlcoholExcesivo }}</p>
              <p><strong>Amenaza con la Policía:</strong> {{ selectedReport.amenazaPolicia }}</p>
              <p><strong>Amenaza con Extranjeros:</strong> {{ selectedReport.amenazaExtranjeros }}</p>
              <p><strong>Destrozos:</strong> {{ selectedReport.destrozos }}</p>
              <p><strong>Grita e Insulta al Arrendatario:</strong> {{ selectedReport.gritaEInsultaArrendatario }}</p>
              <p><strong>Robos:</strong> {{ selectedReport.robos }}</p>
              <p><strong>Buenas Relaciones con Pasajeros:</strong> {{ selectedReport.buenasRelacionesPasajeros }}</p>
              <p><strong>Avisa con Anticipación la Retirada:</strong> {{ selectedReport.avisaConAnticipacionRetirada }}</p>
              <p><strong>Independiente:</strong> {{ selectedReport.independiente }}</p>
              <p><strong>Privado:</strong> {{ selectedReport.privado }}</p>
              <p><strong>Llavero:</strong> {{ selectedReport.llavero }}</p>
              <p><strong>Mete Gente Ajena:</strong> {{ selectedReport.meteGenteAjena }}</p>
              <p><strong>Trato Clientes:</strong> {{ selectedReport.tratoClientes }}</p>
              <p><strong>Comentario:</strong> {{ selectedReport.comentario }}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showDeleteModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Confirmar Eliminación</h5>
              <button type="button" class="close" @click="closeDeleteModal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro de que deseas eliminar este reporte?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" @click="deleteReport">Sí</button>
              <button type="button" class="btn btn-secondary" @click="closeDeleteModal">No</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    data() {
      return {
        searchQuery: '',
        showModal: false,
        showDeleteModal: false,
        selectedReport: null,
        reportToDelete: null,
        currentPage: 1,
        reportsPerPage: 25,
        searchType: 'nombre',
      };
    },
    computed: {
      ...mapState({
        reports: state => state.reports.reports,
      }),
      filteredReports() {
        return this.reports.filter(report => {
          if (this.searchType === 'nombre') {
            return report.nombre.toLowerCase() === this.searchQuery.toLowerCase();
          } else if (this.searchType === 'apellido') {
            return report.apellido.toLowerCase() === this.searchQuery.toLowerCase();
          } else if (this.searchType === 'identificacion') {
            return report.identificacion.toLowerCase() === this.searchQuery.toLowerCase();
          }
          return false;
        });
      },
      paginatedReports() {
        const start = (this.currentPage - 1) * this.reportsPerPage;
        const end = start + this.reportsPerPage;
        return this.filteredReports.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.filteredReports.length / this.reportsPerPage);
      },
    },
    methods: {
      ...mapActions(['removeReport']),
      viewReport(report) {
        this.selectedReport = report;
        this.showModal = true;
      },
      editReport(id) {
        this.$router.push({ name: 'EditReport', params: { id } });
      },
      confirmDeleteReport(id) {
        this.reportToDelete = id;
        this.showDeleteModal = true;
      },
      async deleteReport() {
        try {
          await this.removeReport(this.reportToDelete);
          this.closeDeleteModal();
        } catch (error) {
          console.error('Error deleting report:', error);
        }
      },
      closeModal() {
        this.showModal = false;
        this.selectedReport = null;
      },
      closeDeleteModal() {
        this.showDeleteModal = false;
        this.reportToDelete = null;
      },
      filterReports() {
        this.currentPage = 1; // Reset to first page on filter
      },
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
        }
      },
    },
    created() {
      // Remove automatic fetching of all reports
    },
  };
  </script>
  
 
