<template>
  <form @submit.prevent="submitForm">
    <div class="form-group">
      <label for="nombre">Nombre:</label>
      <input type="text" id="nombre" v-model="formData.nombre" class="form-control" required>
    </div>
    <div class="form-group">
      <label for="apellido">Apellido:</label>
      <input type="text" id="apellido" v-model="formData.apellido" class="form-control" required>
    </div>
    <div class="form-group">
      <label for="nickNames">Nicknames:</label>
      <div v-for="(nickname, index) in formData.nickNames" :key="index" class="input-group mb-2">
        <input type="text" v-model="formData.nickNames[index]" class="form-control">
        <div class="input-group-append">
          <button v-if="index === 0" class="btn btn-add" type="button" @click="addNickname">+</button>
          <button v-else class="btn btn-remove" type="button" @click="removeNickname(index)">-</button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="nacionalidad">Nacionalidad:</label>
      <select id="nacionalidad" v-model="formData.nacionalidad" class="form-control" required>
        <option value="">Seleccione nacionalidad</option>
        <option v-for="nacionalidad in nacionalidades" :key="nacionalidad" :value="nacionalidad">{{ nacionalidad }}</option>
      </select>
    </div>
    <div class="form-group">
      <label>Tipo de Identificación:</label>
      <div>
        <input type="radio" id="rutOption" value="rut" v-model="formData.idType" required>
        <label for="rutOption">RUT</label>
        <input type="radio" id="dniOption" value="dni" v-model="formData.idType" required>
        <label for="dniOption">DNI</label>
        <input type="radio" id="passportOption" value="passport" v-model="formData.idType" required>
        <label for="passportOption">Pasaporte</label>
      </div>
    </div>
    <div class="form-group">
      <label for="identificacion">Número de Identificación:</label>
      <input type="text" id="identificacion" v-model="formData.identificacion" class="form-control" required>
    </div>
    <div class="form-group">
      <label for="genero">Género:</label>
      <select id="genero" v-model="formData.genero" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="femenino">Femenino</option>
        <option value="transgenero">Transgenero</option>
        <option value="masculino">Masculino</option>
        <option value="otro">Otro</option>
      </select>
    </div>
    <div class="form-group">
      <label for="telefono">Número de Teléfono:</label>
      <div v-for="(telefono, index) in formData.telefono" :key="index" class="input-group mb-2">
        <select v-model="formData.telefono[index].countryCode" class="form-control" required>
          <option value="">Seleccione código de país</option>
          <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.name }} ({{ country.code }})</option>
        </select>
        <input type="tel" v-model="formData.telefono[index].number" class="form-control" required>
        <div class="input-group-append">
          <button v-if="index === 0" class="btn btn-add" type="button" @click="addTelefono">+</button>
          <button v-else class="btn btn-remove" type="button" @click="removeTelefono(index)">-</button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="email">Correo Electrónico:</label>
      <input type="email" id="email" v-model="formData.email" class="form-control">
    </div>
    <div class="form-group">
      <label for="paga_puntual">Paga Puntual:</label>
      <select id="paga_puntual" v-model="formData.paga_puntual" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="habitacionLimpiaYOrdenada">Habitación Limpia y Ordenada:</label>
      <select id="habitacionLimpiaYOrdenada" v-model="formData.habitacionLimpiaYOrdenada" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="tranquilaYOrdenada">Tranquila y Ordenada:</label>
      <select id="tranquilaYOrdenada" v-model="formData.tranquilaYOrdenada" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="consumeMarihuana">Consume Marihuana:</label>
      <select id="consumeMarihuana" v-model="formData.consumeMarihuana" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="mucho">Sí mucho</option>
        <option value="poco">Sí poco</option>
        <option value="no">No</option>
        <option value="no_observado">No observado</option>
      </select>
    </div>
    <div class="form-group">
      <label for="consumeOtrasDrogas">Consume Otras Drogas:</label>
      <select id="consumeOtrasDrogas" v-model="formData.consumeOtrasDrogas" class="form-control" required>
        <option value="sin_datos">Sin datos</option>
        <option value="mucho">Sí mucho</option>
        <option value="poco">Sí poco</option>
        <option value="no">No</option>
        <option value="no_observado">No observado</option>
      </select>
    </div>
    <div class="form-group">
      <label for="consumoAlcoholExcesivo">Consumo Alcohol:</label>
      <select id="consumoAlcoholExcesivo" v-model="formData.consumoAlcoholExcesivo" class="form-control" required>
        <option value="sin_datos">Sin datos</option>
        <option value="mucho">Sí mucho</option>
        <option value="poco">Sí poco</option>
        <option value="no">No</option>
        <option value="no_observado">No observado</option>
      </select>
    </div>
    <div class="form-group">
      <label for="amenazaPolicia">Amenaza con la Policía:</label>
      <select id="amenazaPolicia" v-model="formData.amenazaPolicia" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="amenazaExtranjeros">Amenaza con Extranjeros:</label>
      <select id="amenazaExtranjeros" v-model="formData.amenazaExtranjeros" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="destrozos">Genera Destrozos:</label>
      <select id="destrozos" v-model="formData.destrozos" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="gritaEInsultaArrendatario">Grita e Insulta al Arrendador:</label>
      <select id="gritaEInsultaArrendatario" v-model="formData.gritaEInsultaArrendatario" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="robos">Robos:</label>
      <select id="robos" v-model="formData.robos" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="buenasRelacionesPasajeros">Buenas Relaciones con Pasajeros:</label>
      <select id="buenasRelacionesPasajeros" v-model="formData.buenasRelacionesPasajeros" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="avisaConAnticipacionRetirada">Avisa con Anticipación la Retirada:</label>
      <select id="avisaConAnticipacionRetirada" v-model="formData.avisaConAnticipacionRetirada" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="independiente">Independiente:</label>
      <select id="independiente" v-model="formData.independiente" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="privado">Privado:</label>
      <select id="privado" v-model="formData.privado" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="llavero">Llavero:</label>
      <select id="llavero" v-model="formData.llavero" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="meteGenteAjena">Mete Gente Ajena:</label>
      <select id="meteGenteAjena" v-model="formData.meteGenteAjena" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="si">Sí</option>
        <option value="no">No</option>
      </select>
    </div>
    <div class="form-group">
      <label for="tratoClientes">Trato clientes:</label>
      <select id="tratoClientes" v-model="formData.tratoClientes" class="form-control" required>
        <option value="">Selecciona una opción</option>
        <option value="sin_datos">Sin datos</option>
        <option value="bien">Bien</option>
        <option value="regular">Regular</option>
        <option value="mal">Mal</option>
      </select>
    </div>
    <div class="form-group">
      <label for="comentario">Comentario:</label>
      <textarea id="comentario" v-model="formData.comentario" class="form-control" placeholder="Ingrese un comentario breve" required></textarea>
    </div>
    <button type="submit" class="btn btn-primary">Agregar Reporte</button>
  </form>
</template>

<script>
import { createReport } from '../api/reports';
import nacionalidades from '../nacionalidades.json';
import countries from '../countries.json';

export default {
  data() {
    return {
      formData: {
        nombre: '',
        apellido: '',
        nickNames: [''],
        nacionalidad: '',
        idType: 'rut',
        identificacion: '',
        genero: 'sin_datos',
        telefono: [{ countryCode: '', number: '' }],
        email: '',
        paga_puntual: 'sin_datos',
        habitacionLimpiaYOrdenada: 'sin_datos',
        tranquilaYOrdenada: 'sin_datos',
        consumeMarihuana: 'sin_datos',
        consumeOtrasDrogas: 'sin_datos',
        consumoAlcoholExcesivo: 'sin_datos',
        amenazaPolicia: 'sin_datos',
        amenazaExtranjeros: 'sin_datos',
        destrozos: 'sin_datos',
        gritaEInsultaArrendatario: 'sin_datos',
        robos: 'sin_datos',
        buenasRelacionesPasajeros: 'sin_datos',
        avisaConAnticipacionRetirada: 'sin_datos',
        independiente: 'sin_datos',
        privado: 'sin_datos',
        llavero: 'sin_datos',
        meteGenteAjena: 'sin_datos',
        tratoClientes: 'sin_datos',
        comentario: ''
      },
      nacionalidades: [],
      countries: []
    };
  },
  methods: {
    async submitForm() {
      try {
        await createReport(this.formData);
        this.$emit('reportCreated');
      } catch (error) {
        console.error('Error al agregar el reporte:', error);
      }
    },
    addNickname() {
      this.formData.nickNames.push('');
    },
    removeNickname(index) {
      this.formData.nickNames.splice(index, 1);
    },
    addTelefono() {
      this.formData.telefono.push({ countryCode: '', number: '' });
    },
    removeTelefono(index) {
      this.formData.telefono.splice(index, 1);
    },
    fetchNacionalidades() {
      this.nacionalidades = nacionalidades.nacionalidades;
    },
    fetchCountries() {
      this.countries = countries.countries;
    }
  },
  created() {
    this.fetchNacionalidades();
    this.fetchCountries();
  }
};
</script>
