<template>
  <div class="container">
    <h1 class="my-4">Nuevo Reporte</h1>
    <ReportForm @reportCreated="handleReportCreated" />
    <div v-if="showSuccessMessage" class="alert alert-success mt-3">
      <p>Reporte creado exitosamente</p>
      <button @click="closeSuccessMessage" class="btn btn-secondary">Cerrar</button>
      <button @click="createAnotherReport" class="btn btn-primary">Crear otro reporte</button>
    </div>
  </div>
</template>

<script>
import ReportForm from '../components/ReportForm.vue';

export default {
  name: 'NewReport',
  components: {
    ReportForm,
  },
  data() {
    return {
      showSuccessMessage: false,
    };
  },
  methods: {
    handleReportCreated() {
      this.showSuccessMessage = true;
    },
    closeSuccessMessage() {
      this.showSuccessMessage = false;
      this.$router.push({ name: 'Reports' });
    },
    createAnotherReport() {
      this.showSuccessMessage = false;
    },
  },
};
</script>

<style scoped>
/* Estilos movidos a alerts.css */
</style>
