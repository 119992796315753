<template>
  <button class="scroll-to-top" @click="scrollToTop">
    <i class="fas fa-arrow-up"></i>
  </button>
</template>

<script>
export default {
  name: 'ScrollToTopButton',
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
@import "@/assets/styles/scroll-to-top.css";
</style>
