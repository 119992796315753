<template>
  <div class="login-container">
    <div class="login-card">
      <h1>Login</h1>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email" class="form-label">Email:</label>
          <input type="email" v-model="email" class="form-control" placeholder="Ingresa tu email" required />
        </div>
        <div class="form-group">
          <label for="password" class="form-label">Password:</label>
          <div class="input-group">
            <input :type="passwordFieldType" v-model="password" class="form-control" placeholder="Ingresa tu contraseña" required />
            <button type="button" class="btn btn-outline-secondary" @click="togglePasswordVisibility">
              <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
        <p>
          <a href="#" @click.prevent="openPasswordResetModal">¿Olvidaste tu contraseña?</a>
        </p>
       
      </form>
      <p v-if="error" class="text-danger">{{ error }}</p>
      <p v-if="success" class="text-success">{{ success }}</p>
      <password-reset-modal v-if="showPasswordResetModal" @close="closePasswordResetModal" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PasswordResetModal from '@/components/PasswordResetModal.vue';

export default {
  components: {
    PasswordResetModal
  },
  data() {
    return {
      email: '',
      password: '',
      success: '',
      showPasswordResetModal: false,
      passwordFieldType: 'password'
    };
  },
  computed: {
    ...mapGetters(['getError']),
    error() {
      return this.getError;
    }
  },
  methods: {
    ...mapActions(['loginUser']),
    async login() {
      try {
        const response = await this.loginUser({ email: this.email, password: this.password });
        console.log('Login response:', response); // Debugging line to check login response
        const token = localStorage.getItem('token');
        console.log('Token obtained:', token); // Debugging line to check token
        this.success = 'Login successful!';
        setTimeout(() => {
          this.$router.push('/dashboard');
        }, 1000); // Delay to show the success message before redirecting
      } catch (error) {
        console.error(error);
      }
    },
    openPasswordResetModal() {
      this.showPasswordResetModal = true;
    },
    closePasswordResetModal() {
      this.showPasswordResetModal = false;
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }
  }
};
</script>

<style scoped>
/* Estilos movidos a login.css */
</style>
