<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img src="../assets/images/mundo-informativo.png" alt="Logo" style="height: 40px; padding-left: 20px;">
      </router-link>
      <button class="navbar-toggler justify-content-center" type="button" @click="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center" :class="{ show: !isCollapsed }">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="#about">Inicio</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="#about">Acerca de</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="#about">Registrate</router-link>
          </li>
          <li class="nav-item" v-if="!isAuthenticated">
            <router-link class="nav-link" to="/login">Iniciar Sesión</router-link>
          </li>
         <!-- <li class="nav-item" v-if="!isAuthenticated">
            <router-link class="nav-link" to="/register">Registro</router-link>
          </li>-->
          <li class="nav-item" v-if="isAuthenticated">
            <router-link class="nav-link" to="/profile">Perfil</router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated && isAdmin">
            <router-link class="nav-link" to="/admin">Administración</router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated">
            <router-link class="nav-link" to="/dashboard">Menú principal</router-link>
          </li>
        </ul>
        <ul class="navbar-nav mx-auto justify-content-end">
          <li class="nav-item" v-if="isAuthenticated">
            <button @click="handleLogout" class="btn nav-btn ml-2">
              <i class="fas fa-sign-out-alt"></i> Cerrar Sesión
            </button>
          </li>
          <li class="nav-item">
            <button class="btn nav-btn ml-2" id="restModeButton">
              <i class="fas fa-moon"></i> Modo Descanso Visual
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import '../assets/styles/styles.css';
import '../assets/styles/global.css';

export default {
  name: 'NavBarComponent',
  data() {
    return {
      isCollapsed: true
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAdmin'])
  },
  methods: {
    ...mapActions(['logoutUser']),
    handleLogout() {
      this.logoutUser();
      this.$router.push('/login');
    }
  }
};
</script>

