const axios = require('axios').default;

const API_PROD_URL = 'https://us-central1-mundo-informativo.cloudfunctions.net/api';

// Configurar interceptor para adjuntar el token en cada solicitud
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            console.log("✅ Token válido encontrado:", token);
            config.headers['Authorization'] = `Bearer ${token}`;
        } else {
            console.error("❌ No se encontró un token válido.");
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const register = async (userData) => {
    console.log('Iniciando registro en API con datos:', userData);
    try {
        const response = await axios.post(`${API_PROD_URL}/auth/register`, userData);
        console.log('Respuesta de la API en register:', response);
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            console.log("🔐 Token guardado en localStorage:", localStorage.getItem('token'));
        }
        return response.data;
    } catch (error) {
        console.error("🚨 Error en el registro:", error.response?.data?.error || error.message);
        throw new Error(error.response?.data?.error || 'Registration failed');
    }
};

const login = async (userData) => {
    try {
        const response = await axios.post(`${API_PROD_URL}/auth/login`, userData);
        console.log("📨 Respuesta del servidor:", response.data);
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            console.log("🔐 Token guardado en localStorage:", localStorage.getItem('token'));
        }
        return response.data;
    } catch (error) {
        console.error("🚨 Error en el login:", error.response?.data?.error || error.message);
        throw new Error(error.response?.data?.error || 'Login failed');
    }
};

const logout = () => {
    localStorage.removeItem('token');
    console.log("🚪 Sesión cerrada. Token eliminado de localStorage.");
};

const verifyToken = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("❌ No hay token en localStorage para verificar.");
            return null;
        }
        const response = await axios.get(`${API_PROD_URL}/auth/verify-token`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        console.log("✅ Token verificado. Usuario autenticado:", response.data.user);
        return response.data;
    } catch (error) {
        console.error("🚨 Error verificando token:", error.response?.data?.message || error.message);
        return null;
    }
};

export {
    register,
    login,
    logout,
    verifyToken
};
