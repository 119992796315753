<template>
  <div class="register container">
    <h1>Registro</h1>
    <form @submit.prevent="handleRegister">
      <div class="mb-3">
        <label for="nombre" class="form-label">Nombre:</label>
        <input type="text" v-model="nombre" class="form-control" placeholder="Ingresa tu nombre" required />
      </div>
      <div class="mb-3">
        <label for="apellido" class="form-label">Apellido:</label>
        <input type="text" v-model="apellido" class="form-control" placeholder="Ingresa tu apellido" required />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email:</label>
        <input type="email" v-model="email" class="form-control" placeholder="Ingresa tu email" required />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password:</label>
        <div class="input-group">
          <input :type="passwordFieldType" v-model="password" class="form-control" placeholder="Ingresa tu contraseña" required />
          <button type="button" class="btn btn-add" @click="togglePasswordVisibility">
            <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </button>
        </div>
      </div>
      <div class="mb-3">
        <label for="confirmPassword" class="form-label">Confirmar Password:</label>
        <div class="input-group">
          <input :type="confirmPasswordFieldType" v-model="confirmPassword" class="form-control" required />
          <button type="button" class="btn btn-remove" @click="toggleConfirmPasswordVisibility">
            <i :class="confirmPasswordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </button>
        </div>
      </div>
      <div class="mb-3">
        <label for="fechaNacimiento" class="form-label">Fecha de Nacimiento:</label>
        <input type="date" v-model="fechaNacimiento" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="nacionalidad" class="form-label">Nacionalidad:</label>
        <select v-model="nacionalidad" class="form-control" required>
          <option v-for="nacionalidad in nacionalidades" :key="nacionalidad" :value="nacionalidad">
            {{ nacionalidad }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="nickname" class="form-label">Nickname:</label>
        <input type="text" v-model="nickname" class="form-control" required />
      </div>
      <div class="mb-3 row">
        <div class="col">
          <label for="countryCode" class="form-label">Código de País:</label>
          <select v-model="countryCode" class="form-control" required>
            <option v-for="country in countries" :key="country.code" :value="country.code">
              {{ country.name }} ({{ country.code }})
            </option>
          </select>
        </div>
        <div class="col">
          <label for="telefono" class="form-label">Teléfono:</label>
          <input type="text" v-model="phoneNumber" class="form-control" required />
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Registrar</button>
    </form>
    <p>
      ¿Tienes una cuenta? <router-link to="/login">¡Inicia sesión!</router-link>
    </p>
    <p>
      <a href="#" @click.prevent="openPasswordResetModal">¿Olvidaste tu contraseña?</a>
    </p>
    <p v-if="error" class="text-danger">{{ error }}</p>
    <div v-if="showModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Registro Exitoso</h5>
          </div>
          <div class="modal-body">
            <p>Para agilizar la validación de tu registro enviaremos un mensaje mediante WhatsApp al administrador. Presiona aceptar para tomar contacto y luego enviar el mensaje en la aplicación de WhatsApp de tu teléfono o computador. <b>¡Por favor no actualices la página!.</b></p>
            <a :href="whatsappLink" class="btn btn-success" target="_blank">Enviar mensaje por WhatsApp</a>
            <button type="button" class="btn btn-secondary" @click="closeModal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
    <password-reset-modal v-if="showPasswordResetModal" @close="closePasswordResetModal" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import countries from '@/countries.json';
import nacionalidades from '@/nacionalidades.json';
import PasswordResetModal from '@/components/PasswordResetModal.vue';

export default {
  components: {
    PasswordResetModal
  },
  data() {
    return {
      nombre: '',
      apellido: '',
      email: '',
      password: '',
      confirmPassword: '',
      fechaNacimiento: '',
      nacionalidad: '',
      nickname: '',
      countryCode: '',
      phoneNumber: '',
      error: '',
      showModal: false,
      whatsappLink: '',
      countries: countries.countries,
      nacionalidades: nacionalidades.nacionalidades,
      showPasswordResetModal: false,
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
    };
  },
  methods: {
    ...mapActions('auth', ['register']), // Asegúrate de que el namespace 'auth' sea correcto si estás usando módulos en Vuex
    async handleRegister() {
      console.log('Iniciando registro de usuario');
      if (this.password !== this.confirmPassword) {
        this.error = 'Las contraseñas no coinciden';
        console.log('Error: Las contraseñas no coinciden');
        return;
      }
      const telefono = `${this.countryCode}${this.phoneNumber}`;
      try {
        console.log('Datos del usuario:', {
          nombre: this.nombre,
          apellido: this.apellido,
          email: this.email,
          password: this.password,
          fechaNacimiento: this.fechaNacimiento,
          nacionalidad: this.nacionalidad,
          nickname: this.nickname,
          telefono
        });
        await this.register({
          nombre: this.nombre,
          apellido: this.apellido,
          email: this.email,
          password: this.password,
          fechaNacimiento: this.fechaNacimiento,
          nacionalidad: this.nacionalidad,
          nickname: this.nickname,
          telefono
        });
        const whatsappMessage = `Solicitud de validación de registro:\n` +
          `nombre: ${this.nombre},\n` +
          `apellido: ${this.apellido},\n` +
          `email: ${this.email},\n` +
          `telefono: ${telefono},\n` +
          `nacionalidad: ${this.nacionalidad},\n` +
          `fecha de nacimiento: ${this.fechaNacimiento},\n` +
          `nick: ${this.nickname}\n\n` +
          `acepto y quiero validar mi solicitud para el sistema boletin informativo anomimo.`;
        this.whatsappLink = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;
        this.showModal = true;
        console.log('Registro exitoso, mostrando modal');
      } catch (error) {
        this.error = error.message;
        console.error('Error en el registro:', error);
      }
    },
    closeModal() {
      this.showModal = false;
      this.$router.push('/');
    },
    openPasswordResetModal() {
      this.showPasswordResetModal = true;
    },
    closePasswordResetModal() {
      this.showPasswordResetModal = false;
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style scoped>
/* Estilos movidos a register.css */
</style>
