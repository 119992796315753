<template>
  <div class="about-container">
    <div id="heroSection" class="hero-section text-center py-5">
      <div class="container">
        <h1 class="display-4">Bienvenido a tu Mundo Informativo</h1>
        <p class="lead">Inicia sesión para acceder a todas nuestras funcionalidades.</p>
        <button @click="goToLogin" class="btn btn-secondary btn-lg mx-2">
          <i class="fas fa-sign-in-alt"></i> Iniciar Sesión
        </button>
      </div>
    </div>
    <div id="about" class="row">
      <div class="col-md-8">
        <h1 class="display-4 text-center mb-4">Quiénes Somos</h1>
        <p class="lead text-justify">
          Somos una comunidad de dueños de hospedajes comprometidos con la seguridad, la transparencia y la confianza en la industria del alojamiento. Sabemos lo importante que es proteger nuestros espacios y tomar decisiones informadas, por eso creamos una plataforma segura, anónima y colaborativa, donde los anfitriones pueden compartir información verificada sobre huéspedes de manera justa y responsable. Desde 2020, hemos trabajado juntos para construir un entorno donde cada dueño de hospedaje se sienta respaldado y con el control necesario para ofrecer una experiencia más segura. Nuestra prioridad es lograr un equilibrio entre privacidad, equidad y protección, fomentando una comunidad de apoyo basada en el respeto mutuo. Si compartes nuestra visión y valores, aquí encontrarás un espacio donde no estás solo. Juntos, hacemos del hospedaje un lugar más seguro y confiable para todos.
        </p>
      </div>
      <div class="col-md-4 text-center">
        <img src="../assets/images/mundo-informativo.png" alt="Quiénes Somos" class="img-fluid mx-auto d-block mt-4">
      </div>
    </div>
    <div class="row mt-4 vision-section">
      <div class="col-md-6">
        <h2 class="text-center mb-4">🌎 Nuestra Visión</h2>
        <p class="lead text-justify">
          Imaginamos un mundo donde los dueños de hospedajes puedan operar con confianza y tranquilidad, tomando decisiones informadas para proteger sus espacios y brindar la mejor experiencia posible. Queremos ser la plataforma en la que confían los anfitriones, un lugar seguro y anónimo donde compartir información verificada sobre huéspedes sea algo simple, justo y efectivo. Creemos que la transparencia y la comunidad son la clave para un ecosistema de hospedaje más seguro. Por eso, trabajamos día a día para garantizar un equilibrio entre privacidad, equidad y confianza, asegurando que cada miembro de nuestra comunidad se sienta respaldado y protegido. Somos una solución para dueños de hospedaje hecha por dueños de hospedajes.
        </p>
        <img src="../assets/images/vision.png" alt="Nuestra Visión" class="img-fluid mx-auto d-block mt-4">
      </div>
      <div class="col-md-6">
        <h2 class="text-center mb-4">🎯 Nuestra Misión</h2>
        <p class="lead text-justify">
          Alojar a alguien en tu espacio debería ser una experiencia segura y sin preocupaciones. Por eso, nuestra misión es brindar a los dueños de hospedajes una herramienta confiable y anónima, donde puedan compartir y consultar información verificada sobre huéspedes de manera justa y segura. Creemos en el poder de la comunidad y en la importancia de la transparencia, siempre respetando la privacidad de todas las partes. Nuestro compromiso es seguir mejorando nuestra plataforma, ofreciendo un espacio donde los anfitriones se sientan respaldados, protegidos y con el control necesario para tomar decisiones informadas. Juntos, estamos construyendo un ecosistema de hospedaje más seguro, más justo y más confiable para todos.
        </p>
        <img src="../assets/images/mision.png" alt="Nuestra Misión" class="img-fluid mx-auto d-block mt-4">
      </div>
    </div>
    <div id="call-to-action">
      <CallToAction />
    </div>
  </div>
</template>

<script>
import CallToAction from '../components/CallToAction.vue';

export default {
  name: 'AboutView',
  components: {
    CallToAction
  },
  methods: {
    goToRegister() {
      this.$router.push('/register');
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
/* Estilos movidos a about.css */
</style>
