import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; // Asegúrate de que esta línea esté presente
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/global.css"; // Import global styles
import axios from 'axios';


const app = createApp(App);

// Verificar el token al iniciar la aplicación
const token = localStorage.getItem('token');
if (token) {
  store.commit('auth/SET_TOKEN', token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Verificar y mostrar el token almacenado en localStorage
console.log("Token actual en localStorage:", localStorage.getItem('token'));

store.dispatch('tryAutoLogin'); // Ensure auto-login is attempted on app initialization

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      store.dispatch('tryAutoLogin').then(() => {
        if (!store.getters.isAuthenticated) {
          next({ name: "Login" });
        } else {
          next();
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

app.use(router);
app.use(store); // Asegúrate de que esta línea esté presente
app.mount("#app");
 