import axios from 'axios';
import router from '../router';

const API_PROD_URL = 'https://us-central1-mundo-informativo.cloudfunctions.net/api';

const api = axios.create({
  baseURL: API_PROD_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set the token in the headers for each request
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Handle unauthorized errors
api.interceptors.response.use(response => response, error => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token');
    router.push('/login');
  }
  return Promise.reject(error);
});

export const createReport = async (data) => {
  try {
    const response = await api.post('/reports', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getReports = async () => {
  try {
    const response = await api.get('/reports');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getReportById = async (id) => {
  try {
    const response = await api.get(`/reports/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateReport = async (id, data) => {
  try {
    const response = await api.put(`/reports/${id}`, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteReport = async (id) => {
  try {
    console.log('API call to delete report with ID:', id); // Agregar mensaje de consola
    const response = await api.delete(`/reports/${id}`);
    return response.data;
  } catch (error) {
    console.error('API error deleting report:', error); // Agregar mensaje de consola
    throw error.response.data;
  }
};

export const getReportsByUser = async (userId) => {
  try {
    const response = await api.get(`/reports?createdBy=${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const searchReports = async (type, query) => {
  try {
    const response = await api.get(`/reports/search?type=${type}&query=${query}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
